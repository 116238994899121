import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router, Switch, Route, Redirect, Link } from 'react-router-dom'
import './base.css'
import Demo1 from './demos/Demo1'
import img1 from './logononame.png'

// Demos inspired by:
// https://twitter.com/beesandbombs/status/1329796242298245124

function App() {
  return (
    <Router basename="/">
      <div className="frame">

   
      </div>
      <div className="content">
          
        <h2 className="content__title">coming soon</h2>
      </div>
      <div id="animation">
        <Switch>
          <Route exact path="/">
            <Demo1 />
          </Route>
         
        </Switch>
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
